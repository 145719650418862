//
// Sticky Icon
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================



@include exports("stickyicon") {


  .nav-contact {
    background-color: $sw_darkgreen;
    padding: 3px 0;
    margin-right: -20px;
    margin-bottom: -5px;
    margin-left: -20px;
    text-align: center;
    font-weight: bold;
    text-transform: none;
    //display: none;
    visibility: hidden;
    max-height: 0;
    transition: top 300ms ease 0s;
    overflow: hidden;

    &.showme {
      display: block;
      height: auto;
      visibility: visible;
      border-top: solid 1px $sw_green_lighter;
    }

    p, a  {
      @include SW_FT;
    }
  }
/*
  .nav-contact .head {
    color: $sw_font;
    @include SW_FT;
    margin-bottom: 10px;
  }

  .nav-contact a {
    color: $sw_darkgreen_darker;
    @include SW_FT;
    margin-bottom: 15px;
  }

  .nav-contact a.phone-phone{
    @include SW_2_2;
    margin-bottom: 10px;

    &:hover {
      text-decoration: none;
    }
  }

*/
  .nav-contact-box,
  #nav-main .nav-contact {

    p {
      color: $sw_font_light;
      margin-bottom: 0;

    }

    p.mail {
      padding-bottom: 10px;

      a{
        color: $sw_font_light;
        text-decoration: none;

      }
    }
  }

  .nav-contact-icon {
    /*opacity: 1;*/
    background-image: url(../Img/Info_head.svg);
    width: 110px;
    height: 80px;
    background-size: 100%;
    position: absolute;
    right: 0px;
    box-shadow: 0 0 3px rgba(40, 40, 40, 0.2);
  }

  .showme .nav-contact-icon {
    display: none;
  }

  .showme .nav-contact-close {
    background-color: $sw_green;
    width: 110px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0;
  }

  .nav-contact-trigger,
  .nav-contact-box {
    display: none;
  }

  #nav-main .nav-contact-trigger {
    display: block;
    position: relative;
    cursor: pointer;

    span {
      z-index: 111;
      top: 0;
      right: -20px;
    }
  }

  ////
  .nav-contact-box{
    box-shadow: 0 0 3px rgba(40, 40, 40, 0.2);
  }

  .nav-contact {
    //display: none;
    background-color: $sw_darkgreen;
    transition: all 300ms ease 0s;
    padding: 25px 0 10px;

    &.showme {
      display: none;
    }
  }

  .nav-contact-box {
    display: block;
    position: fixed;
    //top: 33vh;
    top: 200px;
    right: -460px;
    padding: 40px 40px;
    background-color: $sw_green;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    transition: right 300ms ease 0s;
  }

  .showme .nav-contact-box {
    right: 113px;
  }

  //Firefox-Hack
  //fixed and outside viewport does not work in FF :-(
  @-moz-document url-prefix() {

    .nav-contact-box,
    .quick-contact-box {
      display: none !important;
    }

    .showme .nav-contact-box,
    .showme .quick-contact-box {
      display: block !important;
    }
  }

  .nav-contact-box,
  #nav-main .nav-contact {

    .head {
      color: $sw_font_light;
      @include SW_2_2;
      margin-bottom: 10px;
      padding-top: 10px;
    }

    a {
      color: $sw_darkgreen_darker;
      @include SW_FT;
      margin-bottom: 20px;
      display: block;
      text-decoration: underline;
    }
    a.phone-phone {
      @include SW_2_2;
      color: $sw_font_light;
      text-decoration:none;
      margin-bottom: 10px;
    }


    p a {
      display: inline;
    }

    p.phone-phone {

      a {
        color: $sw_font_light;
        text-decoration: none;

        &:hover {
          color: $sw_font_light;
          text-decoration: none;
        }
      }
    }
  }

  #nav-main .nav-contact {
    a {
      color: $sw_green_lighter;
    }

    .mail a {
      text-decoration: none;
    }
  }


  .nav-contact-box a:hover,
  a.mail:hover {
    text-decoration: underline;
  }

  #nav-main .nav-contact p a {
    display: inline;
  }

  .nav-contact-close {
    position: absolute;
    padding-top: 13px;
    height: 28px;
    right: 11px;
    top: 18px;
    width: 36px;
    transform: translate3d(250%, 0, 0);
    //box-shadow: 0 0 3px rgba(40, 40, 40, 0.2);
    display: none;
  }

  .nav-contact-text,
  .nav-contact-close {
    transition: all 300ms ease 0s;
  }


  .showme {

    .nav-contact-close {

      transform: translate3d(0, 0, 0);
      box-shadow: 0 0 3px rgba(40, 40, 40, 0.2);
      background-color: $sw_green;
      width: 110px;
      height: 80px;
      position: absolute;
      right: -20px;
      top: -40px;
      display: block;
    }

    nav-contact-icon {
      display: none;
    }

    .nav-contact {
      //display: block;
      max-height: 1000px;
      visibility: visible;
      border-top: solid 2px $sw_green;
      transition: max-height 300ms ease 0s;
    }
  }


  .nav-contact-close::before, .nav-contact-close::after {
    z-index: -1;
    background: #fff;
    content: "";
    //height: 8%;
    //left: 0;
    //width: 85%;
    height: 2px;
    left: 38px;
    width: 30%;
    position: absolute;
    top: 38px;
  }

  .nav-contact-close::before {
    transform: rotate(45deg);
  }

  .nav-contact-close::after {
    transform: rotate(-45deg);
  }

  .nav-contact-trigger {
    display: block;
    position: fixed;
    top: initial;
    right: 0;
    //padding: 20px 20px;
    //letter-spacing: 1.5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    //background-color: #363945;
  }

  .fixed .nav-contact-trigger,
  .fixed .nav-contact-box {
    //top: 23vh;
    //top: calc(33vh - 60px);
    top: 5px
  }

  .nav-contact-trigger:hover,
  .nav-contact-close:hover,
  .quick-contact-close:hover{
    cursor: pointer;
  }

  #nav-main ~ .direct-call-wrap,
  #nav-main ~ .direct-call-wrap ~ .quick-contact-wrap{
    display: none;
  }

 .direct-call-wrap {
   margin-top: -40px;

   &.showme {
     margin-top: 0;
   }
 }


  //Schnellanfrage
  .quick-contact-wrap {
    .quick-contact-icon {
      display: block;
      position: fixed;
      //top: initial;
      width: 110px;
      height: 80px;
      text-align: center;
      padding-top: 22px;
      line-height: 19px;
      cursor: pointer;
      right: 0;
      text-transform: uppercase;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      background-color: $sw_green;
      //top: 48vh;
      top: 495px;

    }

  }
  .fixed .quick-contact-icon {
    top: 495px;
  }

  .quick-contact-box {
    display: none;
    width:600px;

    .response {
      display: none;
    }

    h2 {
      color: $sw_green;
      @include SW_H1;
      text-align: center;
    }
  }

  .quick-contact-form {

    .contact-bubble {
      background-color: $sw_grey_light;
      width: 400px;
      padding: 20px;
      font-weight: 700;
      margin-left: 15px;
      margin-bottom: 15px;
      position: relative;

      &::before {
        background: $sw_grey_light none repeat scroll 0 0;
        content: "";
        display: block;
        height: 15px;
        left: 0px;
        top: 45px;
        position: absolute;
        transform: rotate(45deg);
        width: 15px;
        margin-left: -7.5px;
        margin-top: -20px;
      }

      &.pull-right {
        margin-left: 0px;
        margin-right: 15px;

        &::before {

          left: initial;
          right: -7px;


        }
      }

      input, textarea {
        background-color: $sw_grey_light;
        border: none;
        width: 360px;
        font-weight: 400;
      }

      .form-submit {
        margin-top: 20px;

        input {
          width: 206px;
        }
      }


    }
  }

  //new quick contact

  .quick-contact-wrap {
    position: relative;
    z-index: 2;
  }

  .quick-contact-box {
    display: block;
    position: fixed;
    top: 35px;
    right: -660px;
    padding: 15px 20px;
    background-color: $sw_green;
    font-weight: 700;
    text-align: center;
    text-transform: none;
    transition: right .3s ease 0s;
    width: 510px;
    font-size: 16px;

    height: 540px;
    height: calc(100vh - 150px);
    overflow: auto;
    max-height: 540px;

    p {
      font-size: 16px;
    }

    .btn {
      color: $sw_green;
      border: 2px solid $sw_green;

    }
  }

  .quick-contact-form .contact-bubble {

    padding: 15px 20px
  }

  .quick-contact-form .contact-bubble:before {
    top: 40px;
  }

  .fixed .quick-contact-box {
    top: 35px;
  }

  .showme .quick-contact-box {
    right: 113px;
  }

  .quick-contact-box h2 {
    color: $sw_font_light;
    margin-bottom: 20px;
  }

  .quick-contact-box .response p {
    color: $sw_font_light;
  }

  .quick-contact-close {
    display: none;
  }

  .showme .quick-contact-close {
    background-color: $sw_green;
    width: 110px;
    height: 80px;
    position: absolute;
    right: 0px;
    top: 0;
  }
  .showme {
    .quick-contact-close {

      display: block;
      position: fixed;
      //top: initial;
      width: 110px;
      height: 80px;
      text-align: center;
      padding-top: 22px;
      line-height: 19px;
      cursor: pointer;
      right: 0;
      text-transform: uppercase;
      font-size: 17px;
      font-weight: bold;
      color: #fff;
      background-color: $sw_green;
      //top: 48vh;
      top: 495px;
    }

    .quick-contact-icon {
      display: none;
    }
  }

  .quick-contact-close::before, .quick-contact-close::after {
    z-index: -1;
    background: #fff;
    content: "";
    //height: 8%;
    //left: 0;
    //width: 85%;
    height: 2px;
    left: 38px;
    width: 30%;
    position: absolute;
    top: 38px;
  }



  .quick-contact-close::before {
    transform: rotate(45deg);
  }

  .quick-contact-close::after {
    transform: rotate(-45deg);
  }

  //#nav-main .quick-contact-wrap {
  //  display: block;
  //
  //  .quick-contact-icon {
  //    bottom: -385px;
  //    top: initial;
  //  }
  //
  //}


  @media all and (min-width: 992px) {

    #nav-main ~ .direct-call-wrap,
    #nav-main ~ .direct-call-wrap ~ .quick-contact-wrap
    {
      display: block;
    }

    #nav-main .direct-call-wrap,
    #nav-main .quick-contact-wrap{
      display: none;
    }

    .nav-contact-trigger {
      //top: 33vh;
      top: 200px;
    }

    .showme {

      .nav-contact-close {

        right: 0px;
        top: 0px;
      }

    }

    .nav-contact {
      border-top: solid 2px $sw_green;
    }

    #nav-main .nav-contact {

      .mail a {
        color: $sw_font_light;
      }

      .head {
        color: $sw_font;
      }
    }

    .fixed .nav-contact-trigger,
    .fixed .nav-contact-box {
      //top: 23vh;
      //top: calc(33vh - 60px);
      top: 100px
    }

    .quick-contact-box {
      top: 200px;
    }



    .fixed .quick-contact-box {
      top: 495px;
    }
    .showme .quick-contact-close {
      top: 295px;
      display: block;
    }

    .quick-contact-wrap .quick-contact-icon,
    .quick-contact-wrap .quick-contact-close,
    .quick-contact-wrap .quick-contact-box{
        top: 295px;
    }
    .fixed .quick-contact-icon,
    .fixed .quick-contact-close,
    .fixed .quick-contact-box{
      top: 195px;
    }

    .quick-contact-wrap .quick-contact-box{
      top: 195px;
    }
    .fixed .quick-contact-box{
      top: 100px;
    }

  }

  @media all and (max-width: 767px) {
    .quick-contact-box {
      display: none;
    }
    .showme .quick-contact-box {
      width: 100vw;
      right: 0;
      z-index: 121;
      position: relative;
      max-height: 475px;
      right: 20px;
      display: block;
    }

    .showme .quick-contact-close {
      top: 390px;
    }
    .direct-call-wrap.showme + .quick-contact-wrap {
      display: none;
    }

    .quick-contact-wrap {
      z-index: 121;
    }
  }

  @media all and (max-width: 479px) {
    .quick-contact-form .contact-bubble {
      width: 245px;
    }

    .quick-contact-form .contact-bubble {
      padding: 8px 10px;
    }
    .quick-contact-form .contact-bubble textarea,
    .quick-contact-form .contact-bubble input {
      width: 225px;
    }
    .quick-contact-form .contact-bubble:before {
      top: 32px;
    }

    .showme  .quick-contact-box {
      max-height: 420px;
    }
    /*.quick-contact-box {
      display: none;
    }
    .showme  .quick-contact-box {
      position: relative;
      max-height: 420px;
      right: 20px;
      display: block;
    }
    .showme .quick-contact-close {
      top: 390px;
    }

    .direct-call-wrap.showme + .quick-contact-wrap {
      display: none;
    }*/

  }

}