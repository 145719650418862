//
// Header
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("header") {

  /* ==========================================================================
   IE7 Warning Frame
   ========================================================================== */

  .user-warning {
    background: none repeat scroll 0 0 #DC3228;
    border: 1px solid #C62D24;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #E66F68 inset;
    color: white;
    font-size: 14px;
    margin: 10px auto;
    padding: 8px 20px 4px;
    text-shadow: 0 2px 0 #C62D24;
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .user-warning a {
    font-size: 14px;
    color: #EFEFEF;
    text-decoration: underline;
  }

  .user-warning a:hover {
    color: #000000;
    text-decoration: underline;
  }

  #header {
    margin: 0 auto;
    z-index: 110;
    height: 120px;
    width: 100%;
    border-top: solid 4px $sw_darkgreen;
  }

  #logo-image {
    position: absolute;
    background-image: url(../Img/logo_mobil.svg);
    background-size: 100% 100%;
    height: 42px;
    width: 42px;
    top: 9px;
    z-index: 12;
    transition: all 0s ease 0s;
  }

  #header {
    margin: 0 auto;
    z-index: 110;
    height: 64px;
    width: 100%;
    position: fixed;
    background-color: $sw_green;
    //padding-top: 64px;

    &.fixed .container {
      z-index: 11;
      //width: 100%;
      //margin-left: 0;
      //margin-right: 0;
      //padding-left: 0;
      //padding-right: 0;
    }
  }

  #logo {
    display: block;
    margin: 0 auto;
    padding: 5px 0;
    float: left;
  }

  #logo::after {
    display: table;
    clear: both;
  }


  @media all and (min-width: 992px) {

    #header {
      /*left: 0;*/
      margin: 0 auto;
      /*position: absolute;*/
      /*right: 0;*/
      /*top: 0;*/
      z-index: 110;
      /*background-image: url(../Img/Startseite_Backround_Himmel.png);*/
      /*background-repeat: repeat-x;*/
      /*background-position: left -85px;*/
      height: 180px;
      width: 100%;
      background-color: $sw_background;
      border-top: none;
      padding-top: 0px;

      &.fixed + .page-container {
        padding-top: 70px;

      }
    }

    #header {
      height: 160px;
      border-bottom-width: 15px;
      //transition: all 300ms ease 0s;
      position: relative;
      -webkit-transform: translateZ(0);
      -webkit-overflow-scrolling: touch;
    }

    #header.fixed {
      /*height: 110px;*/
      height: 60px;
      position: fixed;

      #nav-main {
        margin-top: 0;
        background-color: $sw_green;
      }

      .navbar-meta {
        top: -60px;
      }

      & + .page-container {
        padding-top: 160px;
      }
    }

    #header #logo {
      //transition: margin 300ms ease 0s;
    }

    #header.fixed #logo {
      margin-top: 14px;
    }

    #logo-image {
      position: absolute;
      background-image: url(../Img/logo.svg);
      height: 55px;
      width: 182px;
      top: 18px;
      background-size: 100%;
      opacity: 1;
      //transition: all 300ms ease 0s;
    }

    #header.fixed #logo-image {
      opacity: 0;
      display: none;
    }

    #logo-image-stick {
      position: absolute;
      background-image: url(../Img/logo_mobil.svg);
      background-size: 100% 100%;
      height: 50px;
      top: -9px;
      width: 52px;
      opacity: 0;
      z-index: 11;
      transition: all 600ms ease 0s;
    }

    #header.fixed #logo-image-stick {
      opacity: 1;
    }

    #logo {
      display: block;
      margin: 0 auto;
      padding: 5px 0;
      float: left;
    }

    #logo::after {
      display: table;
      clear: both;
    }
  }

}