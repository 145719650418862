//
// Accordion
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("accordion") {

  //accordion
  .accordion .accordion-header {
    background: url(../Img/accordion_up.svg) no-repeat scroll right center transparent;
    padding-right: 50px;
  }

  .accordion .collapsed .accordion-header {
    background-image: url(../Img/accordion_down.svg);
  }

  .accordion {

    h1,h2,h3 {
      margin-bottom: 0;

      a, a:focus, a:hover {
        text-decoration: none;
      }
    }
    h1 a {
      display: block;
      margin: 0;
    }

    //bootstrap override
    //.row {
    //  margin-left: 0;
    //  margin-right: 0;
    //}

    .collapse,
    .collapsing {
      padding-top: $pad-big;
    }
  }

  .container-wrapper .accordion {
    padding-bottom: 0;
  }

  .layout-accordion {
    margin-top: $pad;

    &.icy::before,
    &.icy::after {
      height: 50px;
    }
  }

  @media (max-width: 767px) {
    .accordion .collapse,
    .accordion .collapsing {
      padding-top: $pad-small;
    }
  }
}

