//
// Globals
//=================================================================================================


// Global inserts
//===================================================================

@import "functions";



// @variables
//
//===================================================================

$rem-base:                                    18px !default;
$namespace:                                   swn !default;



// Colors
//-----------------------------------------------

$sw_font:                               rgb(80, 90, 90) !default;
$sw_font_light:                         rgb(255, 255, 255) !default;
$sw_darkgreen:                          rgb(19, 135, 57) !default;
$sw_darkgreen_darker:                   rgb(14, 115, 44) !default;
$sw_green:                              rgb(92, 179, 26) !default;
$sw_green_lighter:                      rgb(132, 197, 83) !default;
$sw_blue:                        rgb(50, 90, 105) !default;
$sw_icy:                        rgb(242, 253, 255) !default;
$sw_grey:                        rgb(184, 188, 188) !default;
$sw_grey_light:                        rgb(230, 230, 230) !default;
$sw_strelix:                      rgb(237,106,0) !default;

$sw_background:                 rgb(255, 255, 255) !default;

$message-red:                                 rgb(255, 0, 0) !default;

$white:                                       rgb(255, 255, 255) !default;
$black:                                       rgb(0, 0, 0) !default;
$gray-dark:                                   rgb(99, 99, 99) !default;
$gray:                                        rgb(192, 192, 192) !default;
$gray-light:                                  rgb(240, 240, 240) !default;



// Spacing
//-----------------------------------------------

$pad:                                         50px !default;
$pad-small:                                   20px !default;
$pad-tiny:                                    10px !default;
$pad-big:                                     80px !default;
$pad-huge:                                    100px !default;


// WIdths
$text-max-width: 780px !default;



// Typography
//-----------------------------------------------

$font-family-primary-sans-serif:              'Lato', sans-serif !default;
//$font-family-primary-serif:                   "Source Serif Pro", Georgia, serif !default;
//$font-family-secondary-serif:                 "Playfair Display", "Times New Roman", serif !default;
$font-family-base:                            $font-family-primary-sans-serif !default;

$font-weight-light:                           300 !default;
$font-weight-heavy:                           700 !default;

$font-size-base:                              18px !default;
$line-height-base:                            27px !default;

$h1-font-size:                                42px !default;
$h2-font-size:                                26px !default;
$h3-font-size:                                22px !default;
$h4-font-size:                                20px !default;
//$h5-font-size:                                $font-size-base !default;
//$h6-font-size:                                $font-size-base !default;

$h1-font-weight:                              700 !default;
$h2-font-weight:                              700 !default;
$h3-font-weight:                              700 !default;
$h4-font-weight:                              300 !default;
//$h5-font-weight:                              700 !default;
//$h6-font-weight:                              700 !default;


$h1-line-height:                              50px !default;
$h2-line-height:                              30px !default;
$h3-line-height:                              1.3 !default;
$h4-line-height:                              1.3 !default;
//$h5-line-height:                              1.3 !default;
//$h6-line-height:                              1.3 !default;


$font-smoothing:                              antialiased !default;

$link-color:                                  $gray-dark !default;
$link-color-hover:                            $link-color !default;




// Media Query Ranges
//-----------------------------------------------

$small-range:                                 (0, rem-calc(640)) !default;
$medium-range:                                (rem-calc(641), rem-calc(1024)) !default;
$large-range:                                 (rem-calc(1025), rem-calc(1440)) !default;
$xlarge-range:                                (rem-calc(1441), rem-calc(1920)) !default;
$xxlarge-range:                               (rem-calc(1921), 99999999rem) !default;

$screen:                                      "only screen" !default;
$landscape:                                   "#{$screen} and (orientation: landscape)" !default;
$portrait:                                    "#{$screen} and (orientation: portrait)" !default;

$small-up:                                    $screen !default;
$small-only:                                  "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-down:                                 "#{$screen} and (max-width: #{upper-bound($small-range)})" !default;
$medium-up:                                   "#{$screen} and (min-width: #{lower-bound($medium-range)})" !default;
$medium-only:                                 "#{$screen} and (min-width: #{lower-bound($medium-range)}) and (max-width: #{upper-bound($medium-range)})" !default;
$large-down:                                  "#{$screen} and (max-width: #{upper-bound($medium-range)})" !default;
$large-up:                                    "#{$screen} and (min-width: #{lower-bound($large-range)})" !default;
$large-only:                                  "#{$screen} and (min-width: #{lower-bound($large-range)}) and (max-width: #{upper-bound($large-range)})" !default;
$xlarge-up:                                   "#{$screen} and (min-width: #{lower-bound($xlarge-range)})" !default;
$xlarge-only:                                 "#{$screen} and (min-width: #{lower-bound($xlarge-range)}) and (max-width: #{upper-bound($xlarge-range)})" !default;
$xxlarge-up:                                  "#{$screen} and (min-width: #{lower-bound($xxlarge-range)})" !default;
$xxlarge-only:                                "#{$screen} and (min-width: #{lower-bound($xxlarge-range)}) and (max-width: #{upper-bound($xxlarge-range)})" !default;



// Cursors
//-----------------------------------------------

$cursor-auto-value:                           auto !default;
$cursor-crosshair-value:                      crosshair !default;
$cursor-default-value:                        default !default;
$cursor-disabled-value:                       not-allowed !default;
$cursor-pointer-value:                        pointer !default;
$cursor-help-value:                           help !default;
$cursor-text-value:                           text !default;



// global grid
//-----------------------------------------------

$page-width:                                  rem-calc(1560) !default;
$column-gutter:                               rem-calc(25) !default;



$box-shadow-default:                          0 2px 5px rgba(0, 0, 0, .25) !default;



// Media
//-----------------------------------------------

$image-path:                                  "../Img" !default;

$text-direction: ltr !default;
$default-float: left !default;
$opposite-direction: right !default;
@if $text-direction == ltr {
  $default-float: left;
  $opposite-direction: right;
} @else {
  $default-float: right;
  $opposite-direction: left;
}




// @mixins
//
//===================================================================

// Import Once
// prevent styles from being loaded
// multiple times for components that
// rely on other components.
//-----------------------------------------------

$modules: () !default;

@mixin exports($name) {
  $modules: $modules !global; // Import from global scope
  $module_index: index($modules, $name); // Check if a module is already on the list

  @if (($module_index == null) or ($module_index == false)) {
    $modules: append($modules, $name) !global;
    @content;
  }
}



// clear floats
//-----------------------------------------------

@mixin clearfix {
  &:before,
  &:after {
    display: table;
    content: " ";
  }

  &:after {
    clear: both;
  }
}

%clearfix {
  @include clearfix();
}



// css3
//-----------------------------------------------

@mixin single-transition($property: all, $speed: 300ms, $ease: ease-out) {
  -webkit-transition: $property $speed $ease;
  transition: $property $speed $ease;
}

@mixin translate2d($horizontal: 0, $vertical: 0) {
  -webkit-transform: translate($horizontal, $vertical);
  transform: translate($horizontal, $vertical);
}

@mixin translate3d($tx, $ty, $tz) {
  -webkit-transform: translate3d($tx, $ty, $tz);
  transform: translate3d($tx, $ty, $tz);
}

@mixin scale($scale) {
  -webkit-transform: scale($scale);
  transform: scale($scale);
}

@mixin translateY($y) {
  -webkit-transform: translateY($y);
  transform: translateY($y);
}



// Makes an element visually hidden, but accessible.
//-----------------------------------------------

@mixin element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}



// Turns off the element-invisible effect.
//-----------------------------------------------

@mixin element-invisible-off {
  position: static !important;
  height: auto;
  width: auto;
  overflow: visible;
  clip: auto;
}

// Key-Frames

@mixin keyframes( $animationName )
{
  @-webkit-keyframes #{$animationName} {
    @content;
  }
  @-moz-keyframes  #{$animationName} {
    @content;
  }
  @-o-keyframes  #{$animationName} {
    @content;
  }
  @keyframes  #{$animationName} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

// Absatzformate von Indesign
// ------------------------------

@mixin SW_HNavi {
  font-size: 22px;
  line-height: 27px;
  @media (max-width: 767px) {
    font-size: 22px;
    line-height: 27px;
  }
}

@mixin SW_Meta {
  font-size: 16px;
  line-height: 23px;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
}

@mixin SW_Bildunterschrift{
  font-size: 16px;
  line-height: 23px;
  //font-weight: 900;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
  }
}

@mixin SW_Headerbild_Text {
  font-size: 42px;
  line-height: 54px;
  @media (max-width: 767px) {
    font-size: 26px;
    line-height: 32px;
  }
}

@mixin SW_Datum {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 17px;
  }
}

@mixin SW_H1 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 700;
  hyphens: auto;
  @media (max-width: 767px) {
    font-size: 32px;
    line-height: 38px;
  }
}

@mixin SW_2_1 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  hyphens: auto;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 23px;
  }
}

@mixin SW_2_2 {
  font-size: 26px;
  line-height: 30px;
  font-weight: 700;
  hyphens: auto;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 23px;
  }
}

@mixin SW_FT {
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  @media (max-width: 767px) {
    font-size: 16px;
    line-height: 22px;
  }
}

@mixin SW_FT_gross{
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
  }
}

@mixin SW_Button {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 17px;
  }
}
@mixin SW_NewsKAT {
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 14px;
  }
}

//text with default style and links etc.
@mixin default-text() {
  //@include SW_FT;
  //color: $sw_font;
  //max-width: 780px;


  p {
    @include SW_FT;
    color: $sw_font;
    max-width: $text-max-width;
  }

  a:not(.btn):not([data-toggle="tab"]) {
    @include SW_FT;
    color: $sw_font;
    text-decoration: underline;

    &:hover {
      color: $sw_green;
    }
  }

  //default cross list
  ul:not(.nav) {
    padding-left: 0;
    list-style-type: none;
    max-width: $text-max-width;

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        position: absolute;
        top: -1px;
        left: 0px;
        content: '+';
        font-weight: 900;
        display: inline-block;
        color: $sw_green;
        font-size: 24px;

      }
    }
  }

}



// @styles
//
//===================================================================

@include exports("global") {


}