//
// Navigation
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================


// @mixins
//
//===================================================================



// @styles
//
//===================================================================



@include exports("nav") {

  //xxx

  #nav-main {
    font-weight: bold;
    z-index: 10;
    left: 0;
    padding: 10px 20px 5px;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: scroll;
    visibility: hidden;
    //border-top: 70px solid #F7941E;
    background-color: $sw_darkgreen;
    //transform: translate3d(100%, 0, 0);
    border-top: 50px solid transparent;
    //TODO
    //transition: all 0.5s ease 0s;
  }

  #nav-main ul {
    list-style-type: none;
    padding-left: 0;
  }

  #nav-main .level1 > li {
    padding: 18px 0;
    position: relative;
    //border-bottom: 1px solid $sw_green;

    &:first-child {
      margin-top: 33px;
    }

    &:last-child {
      padding-bottom: 50px;
    }
  }

  #nav-main .level1 > li > a {
    margin-left: 20px;
    color: $sw_font_light;
  }

  #nav-main .level1 > li:last-child {
    border-bottom: none;
  }

  #nav-main .level1 > li.active:before,
  #nav-main .level1 > li.current:before,
  #nav-main .level1 > li.open:before {
    /*content: "–";*/
  }

  #nav-main .level2 {
    display: none;
    padding: 10px 0;
    margin-left: 25px;
  }

  #nav-main .level2 li {
    padding: 12px 0;

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  #nav-main .level2 li a {
    padding-left: 20px;
    color: $sw_font_light;
    text-transform: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  #nav-main .level2 li:hover a {
    color: $sw_green_lighter;
  }

  #nav-main .nav-meta {
    /*border-top: 1px solid #fff;*/
    font-size: 14px;
    margin-top: 10px;
    padding-top: 10px;
  }

  #nav-main a {
    color: $sw_font_light;

    text-align: left;
    @include SW_HNavi;
  }

  #nav-main .nav-contact a {

    text-align: center;
    @include SW_FT;
  }

  #nav-main.willshow a {
    text-decoration: none;
  }

  #nav-main li.active > a,
  #nav-main li.current > a,
  #nav-main li > a.active {
    color: $sw_green_lighter;
    text-decoration: none;
  }

  .nav-meta li.active > a {
    color: $sw_green_lighter;
  }

  #nav-main li:hover > a {
    color: $sw_green_lighter;
  }

  #nav-main a,
  //#nav-main li.active .level2,
  #nav-main li.current .level2,
  #nav-main .open .level2 {
    display: block;
  }

  #nav-main .open .level2 {
    box-shadow: none;
  }

  #nav-main.allow-animation {
    transition: all 0.5s ease 0s;
  }

  //xxx

  //// Meta

  .menu-button {
    background-color: transparent;
    border: none;
    height: 40px;
    width: 35px;
    right: 20px;
    line-height: 40px;
    margin: 5px;
    position: absolute;
    top: 5px;
    text-decoration: none;
    color: transparent;
    z-index: 1000;
    transition: right 0.4s ease 0s;
  }

  .menu-button.menu-opened {
    right: 12px;
  }

  .menu-button span,
  .menu-button span:before,
  .menu-button span:after {
    transition: all 0.4s ease 0s;
    transform-style: preserve-3d;
  }

  .menu-button span {
    -moz-user-select: none;
    background-color: $sw_darkgreen;
    display: block;
    font-size: 0;
    height: 3px;
    left: 0;
    position: absolute;
    top: 45%;
    width: 100%;
  }

  .menu-button span::before, .menu-button span::after {
    background: none repeat scroll 0 0 $sw_darkgreen;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    width: 100%;
  }

  .menu-button span::before {
    transform: translate3d(0, -400%, 0);
  }

  .menu-button span::after {
    transform: translate3d(0, 400%, 0);
  }

  .menu-opened.menu-button span {
    background-color: transparent;
  }

  .menu-opened.menu-button span:before,
  .menu-opened.menu-button span:after {
    //width: 60%;
  }

  .menu-opened.menu-button span:before {
    transform: translate3d(0, 0px, 0) rotate(45deg);
  }

  .menu-opened.menu-button span:after {
    transform: translate3d(0, 0px, 0) rotate(-45deg);
  }

  
  .login-container {
    //TODO: hide if Strelix is active
    //display: none;

    .form-link-wrapper .form-group {
      margin-bottom: 0px;

      a {
        @include SW_Meta;
        text-decoration: underline;
      }
    }

    .form-link-wrapper {
      margin: 15px 0;

      .form-submit {
        margin-top: 15px;
      }
    }
  }

  ul.open-strelix {
    .login-container.strelix {
      display: block;

    }
  }

  ul.open-sw-portal {
    .login-container.sw-portal {
      display: block;
    }
  }

  ul.level2.open {
    li.open {
      text-align: left;
    }
    li > a {
      display: none;
    }
  }

  .nav-meta {
    @include SW_Meta();
    right: 30px;
    top: 0;
    position: absolute;
    z-index: 1;
    display: none;

    ul {
      padding-top: 30px;
      padding-right: 15px;
    }

    li {
      display: inline;
      margin-left: 25px;
      padding-bottom: 12px;

      a {
        @include SW_Meta;
      }

    }

    li.active > a,
    li > a:hover {
      color: $sw_green;
      text-decoration: none;
      padding-bottom: 5px;
      //border-bottom: 1px solid $sw_green;
    }
    li.active > a {
      border-bottom: 1px solid $sw_green;
    }

    .level2 {
      display: none;
      padding: 0;

      li {
        //margin: 5px 0;
        //display: block;
        //padding: 30px;
        //margin: 0 20px;
        //text-align: center;
        //
        //&:last-child {
        //  border-top: 1px solid $sw_green;
        //}

        a {
          @include SW_FT_gross;

          //padding-left: 10px;
          color: $sw_grey;
          text-transform: none;

          &:hover {
            color: $sw_font;
          }
          //font-weight: 400;
        }

        &:hover {
          //background-color: #F7941E;
          //background-color: $sw_green;
          //margin: 0;
          //z-index: 222;
          //transform: translateY(0px);
          //
          //
          //& + li {
          //  border: none;
          //}
          //a {
          //  //color: $sw_green;
          //  color: $sw_font_light;
          //  border: none;
          //}
        }
      }

      &.open {

        li {
          margin: 0;
          padding: 0;
          border: none;
          text-align: left;
          //color: $sw_grey;

          &:hover {
            background-color: $sw_background;

            a {
              color: $sw_grey;
              border: none;
            }
          }

          a {
            color: $sw_grey;

            &:hover {
              color: $sw_green;
            }
          }
        }
      }
    }
  }

  #nav-main.willshow .nav-meta {
    display: block;
    top: initial;
    right: initial;
    width:100%;
    margin-left: -20px;
    border-top: solid 2px $sw_green_lighter;

    li {
      display: block;
      padding-top: 5px;

      a {
        display: inline;
        text-align: center;
        @include SW_Meta;
      }
    }
  }

  //ausnahme
  #nav-main.willshow ul.box-shadow {
    box-shadow: none;
  }

  #nav-main:target,
  #nav-main.willshow {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }

  body .page-container::after,
  body #footer::after {
    content: "";
    transition: all 0.5s ease 0s;
  }

  body.grayed .page-container::after,
  body.grayed #footer::after {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    background: rgba(255, 255, 255, 0.7);
  }

  .search-area {
    position: fixed;
    height: 60px;
    width: 100%;
    left: 0;
    background-color: $sw_green;
    z-index: 11;
  }

//////////////////////////////////// MEDIA QUERY //////////////////////////////////
  @media all and (min-width: 992px) {

    .menu-button,
    #nav-main {
      display: none;
    }

    #nav-main {
      //transform: translate3d(0px, 0px, 0px);
      //background-color: $sw_green;
      background-color: transparent;
      display: block;
      position: static;
      margin-top: 100px;
      padding: 0;
      visibility: visible;
      border: none;
      padding-right: 0;
      overflow: visible;

      ul {
        margin-bottom: 0;
        padding-left: 0;
      }

      li {
        display: inline-block;
        //margin-left: 45px;
      }

      .level1 > li {

        padding: 16px 0 17px;
        margin: 0;
        position: relative;
        border-bottom: none;

        > a {
          padding: 0 30px;
          border-right: 1px solid $sw_darkgreen_darker;
          //margin-left: 10px;
          color: $sw_font_light;
          margin-left: 0px;
          text-decoration: none;
        }

        > a:hover,
        &.current > a,
        &.active > a,
        > a.active {
          color: $sw_darkgreen_darker;
        }

        &:last-child > a {
          border-right: none;
        }

        &:first-child {
          margin-top: inherit;
        }

        &:last-child {
          padding-bottom: inherit;
        }
      }
    }

    #nav-main li .level2,
    #nav-main li.current .level2,
    #nav-main li.active .level2 {
      position: absolute;
      top: 23px;
      left: calc(50% - 125px);
      z-index: 1000;
      padding-left: 0;
      margin-left: 0;
    }

    #nav-main li .level2 {
      background-color: $sw_background;
      display: block;
      z-index: 1000;
      padding: 15px 0;
      width: 250px;

      &:hover {
        //background-color: $sw_green_lighter;
      }
    }

    .nav-contact-box,
    #content-main img,
    #nav-main li .level2 {
      box-shadow: 0 0 7px 3px rgba(50, 90, 105, 0.2);
    }

    #nav-main li .level2 a {
      @include SW_FT;
      color: $sw_green;
    }

    #nav-main .level2 li:hover {
      background-color: $sw_background;
    }

    #nav-main .level2 li a:hover,
    #nav-main .level2 li:hover a,
    #nav-main .level2 li.current a {
      color: $sw_green;
    }

    #nav-main li .level2,
    #nav-main li.current .level2,
    #nav-main li.active .level2 {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-10px);
      transition: all 300ms ease 0s;
    }

    #nav-main li .level2::before {
      background: #fff none repeat scroll 0 0;
      content: "";
      display: block;
      height: 15px;
      left: 50%;
      top: 13px;
      position: absolute;
      transform: rotate(45deg);
      width: 15px;
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      margin-left: -7.5px;
      margin-top: -20px;
      transition: all 300ms ease 0s;
      box-shadow: -2px -2px 3px -1px rgba(50, 90, 105, 0.1);
    }

    #nav-main li:hover .level2,
    #nav-main li.current:hover .level2,
    #nav-main li.active:hover .level2 {
      visibility: visible;
      opacity: 1;
      transform: translateY(37px);
    }

    #nav-main li:hover .level2::before,
    #nav-main li.current:hover .level2::before,
    #nav-main li.active:hover .level2::before {
      visibility: visible;
      opacity: 1;
    }

    #nav-main li .level2 li {
      margin-left: 0;
      width: 100%;
      text-align: left;
      padding: 0;
    }

    #nav-main li .level2 li:last-child {
      border-bottom: none;
    }

    #nav-main .level2 li a {
      @include SW_FT;
      padding: 3px 25px 3px;
      //letter-spacing: 1px;
      color: $sw_darkgreen;
      display: inline-block;

      &:hover {
        color: $sw_green;
        text-decoration: none;
      }
    }

    #nav-main .nav-meta,
    .search-area,
    #nav-main .nav-contact-trigger {
      display: none;
    }

    #nav-main.allow-animation {
      transition: none;
    }

    .stripe {
      position: absolute;
      background-color: $sw_green;
      bottom: 0;
      height: 60px;
      width: 100%;
    }

    .nav-meta {
      display: block;
    }

    .nav-meta li .level2 {
      position: absolute;
      top: 23px;
      right: -5px;
      background-color: $sw_background;
      display: block;
      z-index: 1000;
      //padding: 12px 0;
      width: 270px;
      box-shadow: 0 0 7px 2px rgba(50, 90, 105, 0.1);
      opacity: 0;
      visibility: hidden;
      transform: translateY(25px);
      transition: all 300ms ease 0s;
    }

    .nav-meta li .level2::before {
      background: #fff none repeat scroll 0 0;
      content: "";
      display: block;
      height: 15px;
      left: 80%;
      top: 13px;
      position: absolute;
      transform: rotate(45deg);
      width: 15px;
      //z-index: 100;
      visibility: hidden;
      opacity: 0;
      margin-left: -7.5px;
      margin-top: -20px;
      transition: all 300ms ease 0s;
      box-shadow: -2px -2px 3px -1px rgba(50, 90, 105, 0.1);
    }

    .nav-meta li:hover .level2,
    .nav-meta li .level2.open {
      visibility: visible;
      opacity: 1;
      transform: translateY(43px);
    }

    .nav-meta li:hover .level2::before,
    .nav-meta li .level2.open::before {
      visibility: visible;
      opacity: 1;
    }

    .login-container {

      .form {
        padding: 20px 20px 0 20px;

        input {
          width: 230px;
        }

        input[type=text],
        input[type=password] {
          padding-left: 15px;
          line-height: 40px;

          border-style: solid;
          border-color: $sw_grey;

        }

        a {
          color: $sw_grey;
        }
      }
    }

    body.grayed .page-container::after,
    body.grayed #footer::after {
      display: none;
    }
  }

  @media all and (max-width: 991px) {
    #nav-main .level1 > li.hasChilds {
      position: relative;
    }

    #nav-main .child-manager {
      height: 40px;
      position: absolute;
      right: 0;
      top: 10px;
      width: 40px;
      z-index: 10;
    }

    #nav-main .child-manager::after {
      content: "+";
      font-size: 40px;
      position: absolute;
      color: $sw_green_lighter;
      right: 10px;
      top: 10px;
    }

    #nav-main .open .child-manager::after {
      content: "\2013";
    }

    #nav-main .link-overview {
      display: none;
    }
  }

}
