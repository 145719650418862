//
// Typography
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================

//irgendwie ist hier die Bootstrap-Einstellung reingeraten, deswegen überschreibe ich es nochmal
//TODO: Beim nächsten mal besser machen
$font-family-base: 'Lato', sans-serif;
$font-size-base: 18px;
$line-height-base: 27px;

// @mixins
//
//===================================================================


// @styles
//
//===================================================================

@include exports("typography") {

  body {
    background: $sw_background;
    color: $sw_font;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
    text-align: left;
  }

  //SW_Meta
  header,
  footer {
    @include SW_Meta();
  }

  //SW_HNavi
  #main-nav {
    @include SW_HNavi();
  }

  //SW_Bildunterschrift
  figcaption {
    @include SW_Bildunterschrift();
  }

  //SW_Button
  .btn {
    @include SW_Button();
    //letter-spacing: 0.1em;
  }

  //SW_NewsKat
  .news-cat {
    @include SW_NewsKAT();
  }

  //SW_Datum
  .news .news-list-date {
    @include SW_Datum();
  }

  //SW_Headerbild Text
  .headerimage-text {
    @include SW_Headerbild_Text();
  }

  // Headings
  //-------------------------------------

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
  }

  h1,
  h1 a{
    font-family: $font-family-base;
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;
    line-height: $h1-line-height;
    margin-bottom: 80px;
  }

  h2,
  h2 a{
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    line-height: $h2-line-height;
    margin-bottom: 50px;
  }

  h3 {
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: $h3-line-height;
  }

  h4 {
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
    line-height: $h4-line-height;
  }

  //h5,
  //.epsilon {
  //  font-size: $h5-font-size;
  //  font-weight: $h5-font-weight;
  //  line-height: $h5-line-height;
  //}
  //
  //h6,
  //.zeta {
  //  font-size: $h6-font-size;
  //  font-weight: $h6-font-weight;
  //  line-height: $h6-line-height;
  //}

  ol,
  ul {
    li {
      margin: .75rem 0;
    }
  }

  ol {
    padding-left: $pad;
  }


  // Specials
  //---------------------------------------------

  h1,h2, h1 a,h2 a {
    color: $sw_blue;
  }

.icy {
  h1,h2,h3,h4, h1 a,h2 a,h3 a,h4 a {
    color: $sw_green;
  }
}

  @media all and (max-width: 767px) {
    h1,
    h1 a{
      font-size: $h1-font-size - 10px;
      line-height: $h1-line-height - 12px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      margin-bottom: 50px;
    }

    h2,
    h2 a{
      font-size: $h2-font-size - 6px;
      line-height: $h2-line-height - 7px;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
      margin-bottom: 30px;
    }

    h3 {
      font-size: $h3-font-size - 4px;
      line-height: $h3-line-height - 5px ;
    }

    h4 {
      font-size: $h4-font-size;
      line-height: $h4-line-height;
    }




  }
}