//
// News
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("footer") {

  .container + #footer {
    margin-top: 80px;
  }

  #footer {
    color: $sw_font_light;
    background-color: $sw_darkgreen;
    position: relative;
    /*background: url(../Img/woodline.png) repeat-x scroll left center transparent;*/
    //height: 198px;

    #footer-content {

      padding-top: $pad;

      h3 {
        @include SW_Meta;
        color: $sw_green_lighter;
        margin-bottom: $pad-small;
      }

      p, a {
        color: $sw_font_light;
        @include SW_Meta;
      }
    }

    .col {
      padding-bottom: 50px;
    }

    .footer-nav {
      border-top: $sw_green_lighter 1px solid;

      ul {
        padding: 15px 0;
        margin: 0;
        list-style: none;

        li {
          display: inline;
          margin-left: 25px;

        }

        li:first-child {
          margin-left: 0;
        }
      }
    }
  }


  .footer-links li:last-child a:hover {
    color: #fff;
    text-decoration: underline;
  }

  @media all and (max-width: 991px) {
    #footer {
      text-align: center;
    }

    .footer-nav {
      padding: 15px;
    }

  }
}

