//
// Content-elemente
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================

$banner-width: 100%;
$banner-height: 220px;


// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("ce") {

  //special elements
  ///////// Green List ///////////
  ul.ce-bullets,
  ul.ce-menu-1 {
    border-left: $sw_green 1px solid;
    padding-left: 14px;
    margin: 0 170px;

    li {
      position: relative;
      padding-left: 15px;
      padding-bottom: 15px;

      &:last-child,
      p,a {
        //green-line-fix
        line-height: 24px;
        padding-bottom: 0;
      }
    }

    .list-circle {
      position: absolute;
      top: 0px;
      left:-3px;
      content: '';
      display: inline-block;
      width: $circle-height;
      height: $circle-height;
      border-radius: $circle-height;
      background-color: $sw_green;
      margin-left: -$circle-height;
      margin-right: 16px;

      &:before {
        position: absolute;
        top: 2px;
        left: 2px;
        content: '';
        display: inline-block;
        width: ($circle-height)-4;
        height: ($circle-height)-4;
        border-radius: ($circle-height)-4;
        background-color: $sw_background;

      }
    }
  }

  // Banner
  .banner {
    .col-md-4 {
      padding: 10px;
    }

    .banner-box {
      width:$banner-width;
      height: $banner-height;
      background-color: $sw_blue;
      position: relative;
      //margin: 10px;

      &::before {
        width: 100%;
        height: 220px;
        background-color: $sw_blue;
        background-image: url(../Img/logo_mobil.svg);
        background-size: 80%;
        background-position: 170px 75px;
        background-repeat: no-repeat;
        opacity: 0.13;
        //mask: url(../Img/logo_icy.svg) no-repeat 170px 75px;
        //mask-size: 80%;
        content: ' ';
        position: absolute;
        display: block;
      }

      p {
        position: relative;
        text-align: center;
        transform: translateY(-50%);
        top: 50%;
        color: $sw_font_light;
        padding: 15px;
        @include SW_2_2();
      }

    }

    a:hover {
      text-decoration: none;

      .banner-box {
        background-color: $sw_icy;
        box-shadow: 0 0 5px rgba(40, 40, 40, 0.2);

        p {
          color: $sw_font
        }
      }
    }
  }

  .container-wrapper:not(.icy) + .container-wrapper .container.banner {
    padding-top: 0;
    border: none;
    margin-top: -20px;

  }

  .bodytext {
    @include default-text;

    h1,h2,h3,h4 {
      margin-bottom: $pad-tiny;
    }

     li {
       @include SW_FT;

    }
  }

  .only-header {

    h1 {
      margin-bottom: 0;
    }
  }

  .breadcrumb a,
  .breadcrumb span {
    padding: 0 3px;
  }

  //spezial-styling für Bild links oder Rechts:
  .im-style-right .col-md-4 ,
  .im-style-left .col-md-4/*,
  .im-style-center .center*/ {

    img {
      width: 100%;
      height: inherit;
    }
  }

  .im-style-center .center {
    img {
      max-width: 100%;
      height: inherit;
    }
  }


  ///// PERSON
  .icon-tel {
    width: 12px;
    height: 13px;
    background-image: url(../Img/telephone.svg);
    display: inline-block;
    margin-right: 8px;
  }
  .telephone,
  .person-name {
    font-weight: 700;
  }

  .person-name {
    font-size: 22px;
  }

  .contacts img {
    margin-bottom: $pad-small;

    &.dummy-person {
      opacity: 0.5;
    }
  }

  /////Table
  tr+tr {
    border-top: 1px solid $sw_darkgreen;
  }

  .text-center table {
    width: 350px;
    margin: 0px auto;

    tr {
      border-top: none;
    }
  }

  .person-container {

    .single-contact {
      padding-bottom: $pad-small;
    }
  }

  ////iframe
  .iframe-wrapper {
    //display: none;
    visibility: hidden;
    position: absolute;

  }

  //strelix-reminder

  .reminder-container {
    padding-left: 200px;
    padding-right: 200px;

    .reminder-error,
    .reminder-save {
      display: none;
    }

    .error .reminder-error {
      display: block;
      color: red;
    }
  }

  .container-wrapper.strelix {
    h1,h2,h3 {
      color: $sw_strelix;
      margin-bottom: $pad;
    }

    .btn {
      border-color: $sw_strelix;
      background-color: $sw_strelix;
    }

    .btn:hover, .button:hover {
      color: $sw_strelix!important;
      background-color:transparent;
    }

    .form-control:focus {
      border-color: $sw_strelix;
      outline: 0;
      box-shadow: none;
    }

    .error > input,
    .error > select {
      border-color: red;
    }

  }

  @media all and (max-width: 1199px) {

    // Banner
    .banner {

      .banner-box {

        &::before {
          background-position: 140px 100px;
        }
      }
    }

    .reminder-container {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  @media all and (max-width: 991px) {

    // Banner
    .banner {

      .banner-box {
        height: 30vw;

        &::before {
          width: 100%;
          height: 30vw;
          background-position: 20.5vw 15vw;
        }
      }
    }

    ul.ce-bullets,
    ul.ce-menu-1 {
      margin: 0 100px;
    }

    .reminder-container {
      padding-left: 0px;
      padding-right: 0px;
    }

  }

  @media all and (max-width: 639px) {

    // Banner
    .banner {

      .banner-box {

        p {
          font-size: 20px;
          line-height: 22px
        }
      }
    }

    ul.ce-bullets,
    ul.ce-menu-1 {
      margin: 0 30px;
    }
  }

  //supersonderfall für die blöde Betriebsführung Wasserzweckverband
  @media all and (max-width: 390px) {

    // Banner
    .banner {

      .banner-box {

        p {
          font-size: 16px;
          line-height: 20px;
          padding: 10px;
        }
      }
    }
  }

}

