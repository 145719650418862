//
// Buttons
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";




// @styles
//
//===================================================================

@include exports("buttons") {

  .btn,
  .btn:focus,
  .button,
  .button:focus {
    color: $sw_font_light;
    border: 2px solid $sw_green;
    padding: 9px 14px;
    border-radius: 0;
    background-color: $sw_green;
    text-decoration: none;
    //font-weight: 700;
    height: 42px;
    //@include SW_Button;
  }

  .btn:hover,
  .button:hover {
    color: $sw_green !important;
    background-color: transparent;
  }

  .btn:active {
    box-shadow: none;
  }

  .btn-inverted,
  .btn-inverted:focus,
  .button-inverted,
  .button-inverted:focus{
    color: $sw_green;
    border: 2px solid $sw_green;
    //padding: 9px 14px;
    //border-radius: 8px;
    background-color: $sw_background;
    //text-decoration: none;
    //font-weight: 700;
    //height: 42px;
  }

  .btn-inverted:hover,
  .button-inverted:hover {
    color: $sw_font_light !important;
    background-color: $sw_green;
  }

  .btn-noborder,
  .button-noborder {
    border: none;
    padding: 11px 16px;
  }

  .download-icon {

      position: absolute;
      width: 20px;
      height: 20px;
      left: 15px; //TODO: besser machen oder viele Breakpoints
      background-image: url(../Img/download.svg);
  }
  a:hover .download-icon {
    background-image: url(../Img/download_inverted.svg);

  }

  .btn-download {
    padding-left: 44px !important;
  }

  //copy btn to button
  .button {
    @extend .btn;
    //for performance-reason during dev:
    /*display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    border-radius: 4px;
    user-select: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;*/
  }

  @media (max-width: 991px) {

    .btn,
    .btn:focus,
    .button,
    .button:focus {
      padding: 8px 14px;
      height: 36px;

    }

    .download-icon {
      top: 5px;
    }

  }

  @media (max-width: 767px) {

    .btn,
    .btn:focus,
    .button,
    .button:focus {
      padding: 8px 14px;
      height: 36px;

    }

    .download-icon {
      top: 5px;
    }

    .col-btn {
      text-align: center;
    }

  }

  //hier wird es einfach zu klein
  @media (max-width: 400px) {

    .btn,
    .btn:focus,
    .button,
    .button:focus {
      min-height: 36px;
      height: initial;
      white-space: normal;
      padding: 7px 10px;
    }

    .download-icon {
      top: 10px;
    }

  }

}