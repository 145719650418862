// @globals
//
//===================================================================

@import "../base/globals";

/*
  CSS copied from w3_base (EG-setup)
*/

.cols {
  display: flex;
  /*flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;*/
}

.cols .col {
  min-width: 300px;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */

/*Merged Style Start*/

a img, iframe {
  border: none;
}

/*a, #content-main .tx-indexedsearch-redmarkup {*/
/*color: inherit;*/
/*}*/

/*NEWFONTS*/

button {
  outline: 0;
}

html {
  box-sizing: border-box;
}

/* fullscreen setup */
html, body {
  /* give this to all tags from html to .fullscreen */
  height:100%;
}
/*
html, body, input, figure, button, h1, h2, h3, h4, h5, h6, p, .slick-dots, .slick-dots li, nav ol, nav ul, nav li {
  margin: 0;
  padding: 0;
}
*/
iframe {
  display: block;
  max-width: 100%;
  //width: 100%;
}

.iframe-wrapper {
  padding-top: $pad-small;
}

input, textarea {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0 5px;
}

input[type=submit] {
  cursor: pointer;
}

nav ol, nav ul {
  list-style: none;
}

.container ol {
  padding-left: 20px;
}

a:focus {

}

/*p,
table li,
.row li {
  font-size: 16px;
}

p {
  margin-bottom: 10px;
  line-height: 24px;
}

table li,
.row li {
  margin-bottom: 5px;
}
*/
//overwrite default-colors from bootstrap
p,
a {
  color: $sw_font;
  //font-weight: 300;
  font-weight: 400;
  @include SW_FT();
}

//content stuff
.page-container {

  overflow: hidden;
  .icy {
    background-color: $sw_icy;

    &::before {
      content: '';
      display: inherit;
      width: 100%;
      height: 80px;
      background: linear-gradient(to bottom, rgba(80, 90, 90, 0.10) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &::after {
      content: '';
      display: inherit;
      width: 100%;
      height: 80px;
      background: linear-gradient(to top, rgba(80, 90, 90, 0.10) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &+ .icy {
      z-index: 3;

      &::before {
        z-Index: 0;
      }

    }

    &> .container {
      //padding: 0 10px;
      margin-top: -80px;
      //margin-bottom: -80px;
      padding-top: 80px;
      padding-bottom: 0;

    }

  }

  .container {
    z-index: 2;
    padding: $pad-big $pad-tiny;
  }

  .container-wrapper,
  .container-wrapper .container {
    z-index: 2; //wegen header-slider
    position: relative;
    //overflow: hidden; //mist macht probleme, wenn ich ausbrechen will
  }
}

.single-wrapper {
  .container-wrapper + .container-wrapper .container {
    padding-top: $pad-big;
  }
}

.container-wrapper
.container:not(.header-slider) .container {
  width: 100%;
}

.tab-content ul {
  //padding-left: 1em;
  list-style-type: none;

  li {
    padding-left: 38px;


    &:before {
      content: "+";
      color: #5cb31a;
      font-weight: 900;
      font-size: 24px;
      position: absolute;
      left: 15px;
    }
  }
}

$circle-height: 24px !default;


.box-shadow {
  box-shadow: 0 0 7px 2px rgba(50, 90, 105, 0.1);
}

.bodytext,
.bodytext li {
  @include default-text;

}

.text-center p {
  margin-left: auto;
  margin-right: auto;
}

/// overwrite tab
.container .nav-tabs > li {
  margin: 0;
  //background-color: $sw_grey;

  a {
    color: $sw_font_light;
    border: none;
    border-radius: 0;
    background-color: $sw_blue;
    @include SW_2_2;

    &:hover {
      border: none;
      background-color: $sw_green;
      color: $sw_font_light;
    }
  }

  &.active a {
    background-color: $sw_green;
  }

}

.nav-tabs {
  border: none;
}


//Bilderabstände für alle Spezialfälle
.container figure {
  margin-bottom: $pad;

  &.center {
    margin-bottom: $pad-big;

  }
}


.header-slider figure {
  margin-bottom: 0;
}


/////// Border between CEs

.page-container > div > .container {
  border-top: $sw_darkgreen 1px solid;

}

.page-container .icy .container,
.page-container .icy + .container-wrapper .container,
.page-container .header-slider + .container-wrapper .container,
.page-container .container-wrapper:first-child .container,
.page-container .breadcrumb + .container-wrapper .container,
.page-container .only-header + .container-wrapper .container
{
  border-top: none !important;
}

.breadcrumb {
  background-color: $sw_background;
  padding-top: $pad;
  padding-bottom: 0;
  margin-bottom: 0;

  .container {
    padding: 0;
    margin-top: 0;
    color: $sw_font;
    @include SW_Meta;

    a {
      color: $sw_font;
      @include SW_Meta;
    }
  }
}

////////

// Images
//TODO: make it better
.col-md-6 img {
  max-width: 100%;
  height: initial;
  //max-height: 373px;
  width: initial;
}

//merge typo3-styling to bootstrap
.align-center {
  @extend .text-center !optional;
  //TODO: remove when extends works
  text-align: center;
}

//Orange screen on strelix
.page-116, .page-117, .page-118,
.page-79 {

  .bodytext ul:not(.nav) li:before {
    color: $sw_strelix;
  }

  .page-container>div>.container {
    border-top: 1px solid $sw_strelix;
  }

  tr+tr {
    border-top: 1px solid $sw_strelix;
  }
}


@media all and (min-width: 640px) {

}

@media all and (max-width: 768px) {
  .page-container {
    .icy {
      h1 {
        font-size: 24px;
        line-height: 27px;
      }
    }
  }
}

@media all and (max-width: 991px) {

  .page-container {
    .icy {

      &::before {
        height: 40px;
      }

      &::after {
        height: 40px;
      }
    }

    .container {

      padding: $pad $pad-small;
    }

    padding-top: 64px;

  }

  .breadcrumb {
    padding-top: 0;

    .container {
      padding-top: $pad-small;
      padding-bottom: $pad-small;
      padding-left: 0;
    }
  }

  .single-wrapper {
    .container-wrapper + .container-wrapper .container {
      padding-top: $pad;
    }
  }

}

@media only screen and (min-width: 960px) and (max-width: 990px) {

}

@media all and (min-width: 1280px) {


}

@media all and (min-width: 1400px) {

}

/*Merged Style End*/

#content {
  min-height: 1000px;
}

//bootstrap v4 import
.hidden-xs-up {
  display: none !important;
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

@media print {
  .collapse {
    display: block;
  }
}