//
// News
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";


// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("news") {


  .news {
    margin-top: -30px;

    .category-list {

      span {
        margin-left: 25px;
        margin-right: 25px;
        margin-bottom: 10px;
        display: inline-block;
      }
      a {
        position: relative;
        color: $sw_grey;
        @include SW_NewsKAT;

        &::before {
          content: ' ';
          position: absolute;
          left: 10px;
          top: -1px;
          background-image: url(../Img/checkbox.svg);
          width:20px;
          height: 20px;
        }

        &.active::before {
          background-image: url(../Img/checkbox_x.svg);
        }
      }

      img {
        max-width: 100%;
        height: inherit;
      }
    }

    .news-list-date {
      color: $sw_green;
      @include SW_Datum();
      margin-bottom:17px;
      display: inline-block;
    }

    .article + .article {
      margin-top: $pad-huge - 2px;
    }

    .news-list-link {
      margin-top: $pad - 2px;
    }


    h3 {
      @include SW_2_1;
      margin-bottom: $pad - 3px;
    }

    .teaser-text,
    .news-text-wrap {
      @include default-text;

      .more {
        margin-top: $pad-small - 2px;
        display: inline-block;
      }

    }

    .news-cat {
      text-transform: uppercase;
      //letter-spacing: 0.10em;
      @include SW_NewsKAT;
      margin-top: 8px;
      color: $sw_grey;
    }

    .news-backlink-wrap {
      padding: 20px 0;
    }
  }

  .news-list {
    margin-top: -10px;
    padding-bottom: 80px;
  }

  //remove container-border o news-page
  .page-6 {
    .page-container {
      .container:not(.header-slider) {

        border: none;
        padding-bottom: 0px;

        &:first-child {
          padding-top: 80px;
        }
      }
    }
  }

  .col-md-6 img {
    max-height: 373px;
  }

  .page-navigation {
    display: none;
  }

  .news-single {
    figure {
      max-width: 780px;
    }
  }


  @media (max-width: 767px) {

    .news .category-list a {
      padding-top: 2px;
    }

  }

  @media (max-width: 400px) {

    .news {
      margin-top: -45px;

      .category-list {

        span {
          //margin-left: 15px;
          margin-right: 15px;
        }
      }
    }

    .page-6 {
      .page-container {
        .container:not(.header-slider) {

          &:first-child {
            padding-top: $pad;
          }
        }
      }
    }

  }
}

