//
// Slick Slider
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================

$bullet-color:                                          $gray !default;
$bullet-color-active:                                   $sw_green !default;



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

@include exports("slider") {

  html:not(.cssfilters) .header-slider {
    height: 28.5vw;
  }

  ///// Slick.css
  /* Slider */
  .slick-slider
  {
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  .slick-list
  {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  .slick-list:focus
  {
    outline: none;
  }
  .slick-list.dragging
  {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list
  {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track
  {
    position: relative;
    top: 0;
    left: 0;

    display: block;
  }
  .slick-track:before,
  .slick-track:after
  {
    display: table;

    content: '';
  }
  .slick-track:after
  {
    clear: both;
  }
  .slick-loading .slick-track
  {
    visibility: hidden;
  }

  .slick-slide
  {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide
  {
    float: right;
  }
  .slick-slide img
  {
    display: block;
  }
  .slick-slide.slick-loading img
  {
    display: none;
  }
  .slick-slide.dragging img
  {
    pointer-events: none;
  }
  .slick-initialized .slick-slide
  {
    display: block;
  }
  .slick-loading .slick-slide
  {
    visibility: hidden;
  }
  .slick-vertical .slick-slide
  {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  ////slick.css END

  .slick-slider .slick-arrow {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 30px;
    z-index: 100;
  }

  .slick-arrow {
    color: #fff;
    font-size: 25px;
    /*position: relative;*/
  }


  .slick-arrow .new-arrow {
    background: transparent url("../Img/slider_arrow_left.svg") no-repeat scroll center center;
    background-size: 100% 100%;
    width: 16px;
    height: 32px;
    display: block;
  }

  .slick-prev .new-arrow {
    //transform: rotate(90deg);
    position: relative;
    right: -15px;
  }

  .slick-next .new-arrow {
    background: transparent url("../Img/slider_arrow_right.svg") no-repeat scroll center center;
    position: relative;
    //right: 15px;
  }

  .mansion .slick-dots {
    visibility: hidden;
  }

  .slick-slider .slick-prev {
    background-position: left center;
    left: 2%;
  }

  .slick-slider .slick-next {
    background-position: right center;
    right: 2%;
  }

  .slick-slider img:first-child {
    display: block;
  }

  .slick-slider .slick-dots {
    bottom: 0;
    left: 0;
    list-style: none;
    position: absolute;
    right: 0;
    text-align: center;
    padding: 0;
  }

  .slick-slider .slick-dots li {
    border: 1px solid #fff;
    border-radius: 100%;
    display: inline-block;
    height: 12px;
    margin: 0 5px;
    width: 12px;
    cursor: pointer;
    box-shadow: 0 0 1px 1px;
  }

  .slick-slider.products-other .slick-dots li {
    border: 1px solid rgb(172, 192, 208);
  }

  .slick-slider .slick-dots li:hover {
    cursor: pointer;
  }

  .slick-slider .slick-dots li.slick-active, #content-main .col {
    background: $sw_green;
  }

  .slick-slider.products-other .slick-dots li.slick-active {
    background: rgb(172, 192, 208);
  }

  .slick-slider img,
  .slick-slider .slick-dots button {
    display: none;
  }

  .container,
  .slider-wrapper {
    position: relative;
  }

  .slider-wrapper {
    width: 100%;
  }

  .page-container .header-slider {
    max-width: 1920px;
    width: 100%;
    padding: 0;
    max-height: 550px;
    overflow: hidden;
  }

  .slick-slide img {
    //width:  100%;
    height: 100%;
    //transition: transform 0.5s;
  }

  .header-slider {

    .slick-active img {
      left: 50%;
      position: relative;
      transform: translateX(-50%);
      //transition: transform 0.5s;
    }

    &.slick-slider .slick-next {
      right: 10%;
    }

    &.slick-slider .slick-prev {
      left: 10%;
    }

    .slick-slide {
      max-height: 550px;

    }

    .figcaption {
      position: absolute;
      top: -380px;;
      //left: -40px;
      left: 10px;
      width: 490px;

      span {
        color: $sw_green;
        background-color: $sw_icy;
        @include SW_Headerbild_Text;
        text-transform: uppercase;
        box-shadow:
                0.3em 0 0 $sw_background,
                -0.3em 0 0 $sw_background;
      }

      a.btn {
        //margin-top: 20px;
        position: absolute;
        bottom: -75px;
        left: -12px;

        &:hover {
          background-color: $sw_background;
        }
      }
    }

    .container {
      padding: 0;
    }
  }

  /*Slick Plugin End*/

  @media (max-width: 1199px) {
    .slick-slide {
      img {
        width: 100%;
      }
    }
  }


  @media (max-width: 991px) {
    .page-container .header-slider {

      //padding-top: 64px;

      .figcaption {
        position: absolute;
        top: -200px;;
        //left: -40px;
        left: 40px;
        width: 490px;
      }
    }
  }

  @media (max-width: 767px) {

  }

  @media (max-width: 575px) {
    .header-slider .slick-slide img {
      height: 180px;
      width: auto;
    }

  }


}