//
// News
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("product") {
  //Products
  .products {
    @include default-text;

    h1 {
      margin-bottom: $pad;
    }

    h2 {
      color: $sw_green;
      margin-bottom: 10px;
    }

    .product-box {
      background-color: $sw_blue;
      padding: 30px 25px 25px 25px;

      h4, h5, h3 {
        padding-bottom: 25px;
      }

      p, li, h3, h4, h5 {
        color: $sw_font_light;
      }

      p, li {
        @include SW_FT;
      }

      .btn:hover {
        color: $sw_font_light;
      }

    }

    .btn {
      margin-top: 50px;
    }

    .new-icon {
      position: absolute;
      width: 75px;
      height: 75px;
      left: -80px;
      top: 20px;
      background-image: url(../Img/neu.svg);
    }

    .nav-tabs > li {
      width:50%;
      /*margin: 0;
      //background-color: $sw_grey;

      a {
        color: $sw_font_light;
        border: none;
        border-radius: 0;
        background-color: $sw_blue;
        @include SW_2_2;

        &:hover {
          border: none;
          background-color: $sw_green;
          color: $sw_font_light;
        }
      }

      &.active a {
        background-color: $sw_green;
      }
*/
    }

    .nav-tabs {
      border: none;
    }

    .tab-content {
      padding: 30px 25px 25px 25px;
    }

    .dummy-product {
      width: 30%;
      opacity: 0.6;
    }
  }

  //fixes on icy-container
  .icy {
    .products {
      .new-icon {
        top: -60px;
      }

      .product-box {
        background-color: $sw_background;

        @include default-text;
      }
    }
  }

  // Banner
  .products {

    .row {

      .col-md-6:last-child {
        padding-top: 25px;
      }

    }
  }

  .strelix.products {
    h1, h2 {
      color: $sw_strelix;
    }
    ul:not(.nav) li:before {
      color: $sw_strelix;
    }

    .btn {
      color: $sw_font_light;
      background-color: $sw_strelix;
      border-color: $sw_strelix;

      &:hover {
        color: $sw_strelix !important;
        border-color: $sw_strelix;
        background-color: transparent;
      }
    }

  }

  @media all and (min-width: 992px) {
    .products {
      p, li {
        @include SW_FT_gross;
      }

      .row {

        .col-md-6:last-child {
          padding-top: inherit;
        }

      }
    }
  }

}

