//
// Grid-elemente
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================

$banner-width: 100%;
$banner-height: 220px;


// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("gridelements") {


  //einspalter
  .grid-header {

    h1,h2,h3 {
      margin-bottom: 0;
    }

    &+ .container-wrapper .container {
      border: none;
      //padding-top: 0;
    }

  }

  .container-wrapper.icy + .grid-header {
    margin-top: $pad-big;

    .container {
      border-top: solid 1px $sw_darkgreen;
    }
  }

  .gridelement {
    .container {

      padding: 0;
    }

    .container-wrapper + .container-wrapper {
      padding-top: $pad-tiny;
    }
  }

  //fix for clear after 2-col grid
  .layout-cols-11 + .layout-cols-11 {
    .container {
      padding-top: 0;
      border: none;
      margin-top: -20px;
    }
  }

  // Abstand im Zweispalter
  .gridelement .container figure {
    margin-bottom: $pad;

  }

  .gridelement .single-wrapper .container figure {
    margin-bottom: 0;

  }

  //handling of images in grids
  .cols-11 {

    img {
      max-width: 100%;
      height: inherit;
    }

    //spezielle Übrschriften

    h2 {
      color: $sw_font;
      margin-bottom: 25px;
    }

    .bodytext h3 {
      @include SW_2_2;
      color: $sw_font;
      font-weight: 400;
      margin-bottom: $pad-small;
    }
  }

  .cols-111 {

    .container-wrapper {
      padding-top: 0;

      .row {
        img {
          max-width: 100%;
          height: inherit;
        }
      }

      .container figure {
        margin-bottom: 30px;

      }
    }
  }

  @media all and (max-width: 991px) {

    .gridelement .col:not(:first-child) {
      padding-top: 20px;
    }

    // Abstand im Zweispalter
    .gridelement .container figure {
      margin-bottom: $pad-tiny;

      &~ .bodytext {
        padding-bottom: $pad-small;
      }

    }
  }

}

