//
// Brand
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================

//will be deleted
@include exports("brand") {

/*
  #logo-image {
    position: absolute;
    left: 10px;
    !/*background-image: url(../Img/Startseite_Backround_Insel.png);*!/
    height: 100px;
    width: 398px;
    background-size: 100%;
  }
*/
  @media all and (min-width: 992px) {

    #logo {
      margin-left: 0;
      margin-top: 38px;
      width: auto;
      position: relative;

      img {
        height: auto;
      }
    }

    #logo-image-stick {
      transition: all 500ms ease 0s;
    }

  }
}
