//
// Search
//=================================================================================================


// @globals
//
//===================================================================

@import "../base/globals";



// @variables
//
//===================================================================



// @mixins
//
//===================================================================



// @styles
//
//===================================================================


@include exports("search") {

  .quicksearch {
    display: block;
    position: absolute;
    top: 30px;
    right: 0;
    float: right;
    width: 70%;
    //left:100%;
    height: 30px;

    overflow: hidden;

    .search-container {
      z-index: 2;

      position: absolute;
      left: 100%;
      width: 90%;
      transition: 500ms ease 0s;
    }

    form {
      width: 96%;
      float: left;
      padding-left: 20px;
      transition: 500ms ease 0s;
      background-color: $sw_background;
    }

    &.open {

      .search-container {
        left: 10%;
        transition: 500ms ease 0s;
      }

      form {
        display: block;
      }
    }

    .icon {
      top: 4px;

    }

    .icon-reset {

    }

    .btn-search {
      font-size: 20px;
      top: 2px;
      position: absolute;
      left: -20px;
      cursor: pointer;
    }

    .btn-remove {
      font-size: 20px;
      top: 2px;
      position: absolute;
      right: 2px;
    }
  }

  /// icons
  .loupe,
  .icon-search {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    left: 25px;
    top: 12px;
    background-image: url(../Img/lupe_search.svg);
    cursor: pointer;

    &:hover {
      //TODO: better colorchange  with css only
      background-image: url(../Img/lupe_search_green.svg);
    }
  }
  .reset,
  .icon-reset {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    right: 25px;
    top: 12px;
    background-image: url(../Img/close_x.svg);
    cursor: pointer;

    &:hover {
      //TODO: better colorchange  with css only
      background-image: url(../Img/close_x_green.svg);
    }
  }
  ///icons end

  .search {
    margin-top: -45px;

    input.searchbox-sword {
      height: 42px;
      border-radius: 0;
      border: 1px solid $sw_green;
      @include SW_Meta;

      padding-left: 40px;

    }

  }

  .search-result-count {
    p {
      @include SW_Meta;
      color: $sw_grey;
    }
  }

  .tx-indexedsearch-res {
    .tx-indexedsearch-description {
      @include default-text;
    }

    h2.search-header {

      margin: $pad 0 $pad-small;

      a {

        @include SW_2_1;
        text-decoration: underline;

        &:hover {
          color: $sw_green;
        }
      }
    }

    .tx-indexedsearch-redMarkup {
      color: $sw_green;
    }
  }

  .searchbox-button {
    height: 42px;
    min-width: 100%;
  }


  @media all and (min-width: 992px) {

    .quicksearch {
      display: block
    }

    .mobile-search {
      display: none;
    }


    .fixed {
      .quicksearch {
        display: none;
      }
    }

    .quicksearch-submit {
      background: #19A1F0;
      border: none;
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding: 5px 0;
      vertical-align: top;
      width: 50px;
    }

    .quicksearch-sword {
      //background: rgba(255, 255, 255, 0.8);
      border: none;
      display: inline-block;
      font-size: 16px;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      vertical-align: top;
      width: 500px;

    }

  }

  @media all and (max-width: 991px) {

    .search-area {


    }
    .quicksearch {
      display: none;
      position: relative;
      top: 0;
      right: 0;
      float: left;
      width: 70%;
      height: 36px;
      overflow: hidden;
      margin: 17px 60px;
      z-index: 12;

      .search-container {
        position: relative;
        left: initial;
        width: 100%;
        transition: none;
      }

      form {

        padding-left: 0;
        transition: none;
        background-color: transparent;

        input.quicksearch-sword {

          line-height: 24px;
          font-size: 22px;
          background-color: $sw_green;
          color: $sw_darkgreen_darker;
          border: none;
          width:100%;
          padding-left: 20px;

          border-left: 2px solid $sw_background;

          &::before {
            position: absolute;
            border-left: 1px solid $sw_background;
            content: " ";
          }

          &:focus {
            outline: #0e732c auto 1px;
          }

        }
      }
    }

    .grayed .mobile-search {
      display: block;
    }


    input::-webkit-input-placeholder {
      color: $sw_darkgreen_darker;
    }
    input::-webkit-input-placeholder {
      color: $sw_darkgreen_darker;
    }
    input:-ms-input-placeholder {
      color: $sw_darkgreen_darker;
    }
    .quicksearch input:-webkit-autofill {
      background-color: $sw_green;
      color:  $sw_darkgreen_darker;
      -webkit-box-shadow: none;
      -webkit-text-fill-color: $sw_green;
    }


    .grayed #logo-image {
      background-image: url(../Img/lupe_search_white.svg);
      height: 24px;
      width: 24px;
      top: 17px;
    }
  }
  @media all and (max-width: 480px) {
    .search {
      .button-wrap {
        padding-left: 0;
      }
    }
  }
}

